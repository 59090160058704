import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react"
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { RootState } from "./store"
import { setLogout } from "../../pages/auth/services/authSlice"
const { REACT_APP_API_BASEURL } = process.env

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_BASEURL,
  // referrerPolicy: "unsafe-url",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }

    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 })

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQueryWithRetry(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(setLogout())
  }
  return result
}

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const baseAPI = createApi({
  reducerPath: "roamingPartners",
  baseQuery: baseQueryWithReAuth,
  refetchOnReconnect: true,
  tagTypes: [
    "Auth",
    "Agreements",
    "Charges",
    "ChargesType",
    "Configurations",
    "Countries",
    "Currencies",
    "Directions",
    "IOTs",
    "Partners",
    "PartnerCodes",
    "PartnerServices",
    "PartnerFiles",
    "PartnerServiceTypes",
    "Users",
  ],
  endpoints: () => ({}),
})

export const enhancedApi = baseAPI.enhanceEndpoints({
  endpoints: () => ({
    getSomething: () => "test",
  }),
})
