import React from "react"
import { useLocation, Navigate } from "react-router-dom"
import { useAppSelector } from "../../services/reduxhooks"

type Props = {
  children: JSX.Element
  admin_access: boolean
}

const LoginAuthenticator: React.FC<Props> = ({ children, admin_access }) => {
  const { token, is_logged_in, user } = useAppSelector(state => state.auth)
  const { is_admin } = user
  const location = useLocation()
  if (!is_logged_in || !token) {
    return <Navigate to={`/auth`} state={{ from: location }} replace />
  }
  if (admin_access && !is_admin) {
    return <Navigate to={`/`} state={{ from: location }} replace />
  }
  return children
}

export default LoginAuthenticator
