import styled from "styled-components"

const nav_width = "15vw"

export const MainBody = styled.div`
  background-color: ${props => props.theme.colors.bg.primary};
  position: relative;
  margin-left: ${nav_width};
  transition: margin-left 0.5s;
  padding: 1rem;
  border-color: grey;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`

export const SideNavPrimaryMenu = styled("div")`
  box-shadow: 0px 10px 15px -10px ${props => props.theme.colors.ui.shadow};
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  /* border-right: 2px solid ${props => props.theme.colors.brand.primary}; */
  background-color: ${props => props.theme.colors.bg.secondary};
  height: 100%;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: ${nav_width};
  z-index: 2; /* Stay on top */
`

export const SideNavList = styled("ul")`
  list-style-type: none;
  align-items: flex-end;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
`

interface list {
  active: boolean
}

export const SideNaveListItem = styled("li")<list>`
  background-color: ${props => (props.active ? props.theme.colors.brand.primary : "")};
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;
  float: right;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* box-shadow: ; */
  transition: 2s;
  transition-timing-function: ease-in-out;
`
export const SideNaveListItemIcon = styled("span")`
  background-color: ${props => props.theme.colors.brand.secondary};
  border-radius: 100%;
  color: #000;
  height: 30px;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  width: 30px;
`
interface title {
  active: boolean
}

export const SideNaveListItemTitle = styled("span")<title>`
  color: ${props => (props.active ? props.theme.colors.text.inverse : props.theme.colors.text.primary)};
  text-align: center;
  font-size: 18px;
  height: fit-content;
  width: fit-content;
  vertical-align: middle;
`

export const SideNavBottom = styled("div")`
  position: absolute;
  bottom: 1vh;
  margin: 0px auto;
  height: "fit-content";
  width: 100%;
  border-style: none;
`

export const Header = styled("nav")`
  background-color: ${props => props.theme.colors.brand.primary};
  width: 85vw;
  position: absolute;
  height: 4vh;
  margin-left: ${nav_width};
  float: right;
  z-index: 1;
  padding: 5px;
`

export const HeaderNav = styled.nav`
  background: ${props => props.theme.colors.bg.secondary};
  box-shadow: 0px 10px 20px -10px ${props => props.theme.colors.ui.shadow};
  border-bottom: 0.01rem solid ${props => props.theme.colors.ui.disabled};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2vw;
  padding-top: 1vh;
  max-width: 100%;
  min-height: 5vh;
  max-height: 7vh;
  transition: 0.3s;
  z-index: 1;

  &:hover {
    /* min-height: 8vh; */
  }

  @media screen and (max-width: 768px) {
  }
`
