import React from "react"
import { useLocation, Link } from "react-router-dom"
import { Button, Stack, Badge } from "react-bootstrap"
import { BsCurrencyExchange } from "react-icons/bs"
import { FaPowerOff, FaUserCog, FaHandshake, FaFlag } from "react-icons/fa"
import { RiDashboardFill } from "react-icons/ri"

import { useAppSelector, useAppDispatch } from "../../services/reduxhooks"
import { setLogout } from "../../../pages/auth/services/authSlice"

import { CenterContainer, ResponsiveImageContainer } from "../../components/styled"
import {
  SideNavPrimaryMenu,
  SideNavList,
  SideNaveListItem,
  SideNaveListItemIcon,
  SideNaveListItemTitle,
  SideNavBottom,
} from "./components/LayoutStyledComponents"

const { REACT_APP_HOST_URL } = process.env
const size = 25

const SideNavigatorComponent: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    user: { fullname, email },
  } = useAppSelector(state => state.auth)
  const location = useLocation()
  const path = "/" + location.pathname.split("/")[1]
  const nav_items: { icon: React.ReactElement; link: string; title: string }[] = [
    {
      icon: <RiDashboardFill size={size} />,
      link: "/",
      title: "Dashboard",
    },
    {
      icon: <FaHandshake size={size} />,
      link: "/partners",
      title: "Partners",
    },
    {
      icon: <FaFlag size={size} />,
      link: "/countries",
      title: "Countries",
    },
    {
      icon: <BsCurrencyExchange size={size} />,
      link: "/currencies",
      title: "Currencies",
    },
    {
      icon: <FaUserCog size={size} />,
      link: "/settings",
      title: "Settings",
    },
  ]

  const handleLogout = () => {
    dispatch(setLogout())
  }
  return (
    <>
      <SideNavPrimaryMenu>
        <h4 style={{ textAlign: "center", color: "#f38e27", margin: "1rem" }}>QRoam Partners</h4>
        <SideNavList>
          {nav_items.map((item, index) => (
            <SideNaveListItem key={index} active={path === item.link}>
              <Link to={item.link} style={{ textDecoration: "none" }}>
                <SideNaveListItemIcon>{item.icon}</SideNaveListItemIcon>
                <SideNaveListItemTitle active={path === item.link}>&nbsp;&nbsp;{item.title}</SideNaveListItemTitle>
              </Link>
            </SideNaveListItem>
          ))}
        </SideNavList>
        <SideNavBottom>
          <Stack gap={3}>
            <div>
              {/* <Avatar> */}
              <ResponsiveImageContainer src={`${REACT_APP_HOST_URL}/assets/icons/world.svg`} />
              {/* </Avatar> */}
            </div>
            <div>
              <p style={{ textAlign: "center", fontSize: "16px" }}>{fullname}</p>
              <p style={{ textAlign: "center" }}>
                <Badge pill bg="secondary">
                  {email}
                </Badge>
              </p>
            </div>
          </Stack>
          <CenterContainer width="fit-content" height="fit-content" position="relative">
            <Stack direction="horizontal" gap={3}>
              <Button variant="outline-primary" size="sm">
                <FaUserCog color="#304ffe" />
              </Button>
              <Button variant="outline-danger" size="sm" onClick={() => handleLogout()}>
                <FaPowerOff color="#dd2c00" />
              </Button>
            </Stack>
          </CenterContainer>
        </SideNavBottom>
      </SideNavPrimaryMenu>
    </>
  )
}

export default SideNavigatorComponent
