import React from "react"
import { BrowserRouter as Router, useRoutes } from "react-router-dom"
import LoaderComponent from "../../components/utils/LoaderComponent"
import LoginAuthenticator from "../../components/auth/LoginAuthenticator"
import { FadeInDiv } from "../../components/styled"

// import AuthProvider from "../../components/providers/AuthProvider"
import AppLayoutComponent from "../layout"
const { REACT_APP_BASENAME } = process.env

const dynamicImport = (page: string): React.FC => {
  return React.lazy(() =>
    import(`../../../pages/${page}`)
      .then(screen => {
        return screen
      })
      .catch(err => console.log(err))
  )
}
const AuthPage = dynamicImport("auth")
const SettingsPage = dynamicImport("settings")
const CountriesPage = dynamicImport("countries")
const ChargesPage = dynamicImport("charges")
const CurrencyPage = dynamicImport("currencies")
const HomePage = dynamicImport("home")
const PartnersPage = dynamicImport("partners")
const PartnersDetailsPage = dynamicImport("partners/PartnerDetails")
const NotFoundPage = dynamicImport("notfound")

const AppRoutesConfiguration = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <React.Suspense fallback={<LoaderComponent />}>
          <LoginAuthenticator admin_access={false}>
            <AppLayoutComponent>
              <FadeInDiv>
                <HomePage />
              </FadeInDiv>
            </AppLayoutComponent>
          </LoginAuthenticator>
        </React.Suspense>
      ),
    },
    {
      path: "/auth",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <FadeInDiv>
                <AuthPage />
              </FadeInDiv>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/settings",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <SettingsPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/countries",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <CountriesPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/currencies",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <CurrencyPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/charges",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <ChargesPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/partners",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <PartnersPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
        {
          path: "/partners/:id",
          element: (
            <React.Suspense fallback={<LoaderComponent />}>
              <LoginAuthenticator admin_access={false}>
                <AppLayoutComponent>
                  <FadeInDiv>
                    <PartnersDetailsPage />
                  </FadeInDiv>
                </AppLayoutComponent>
              </LoginAuthenticator>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <React.Suspense fallback={<LoaderComponent />}>
          <NotFoundPage />
        </React.Suspense>
      ),
    },
  ])

  return routes
}

const RouteWrapper = () => {
  return (
    <Router basename={REACT_APP_BASENAME || "/"}>
      <AppRoutesConfiguration />
    </Router>
  )
}

export default RouteWrapper
