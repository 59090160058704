import React from "react"
import HeaderComponent from "./HeaderComponent"
import MainComponent from "./MainBodyComponent"
import SideNavComponent from "./SideNavComponent"

type Props = {
  children: JSX.Element
}

const index: React.FC<Props> = ({ children }) => {
  return (
    <>
      <HeaderComponent />
      <SideNavComponent />
      <MainComponent>{children}</MainComponent>
    </>
  )
}

export default index
