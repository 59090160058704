import React, { ReactElement } from "react"
import { Header, HeaderNav } from "./components/LayoutStyledComponents"
import { FaRegLightbulb, FaMoon } from "react-icons/fa"
import { useAppDispatch, useAppSelector } from "../../services/reduxhooks"
import { setTheme } from "../../services/theme/themeSlice"
import { UserNameAvater } from "../../components/styled"
import { Stack, Form } from "react-bootstrap"

const HeaderComponent: React.FC = () => {
  const dispatch = useAppDispatch()
  const { dark } = useAppSelector(state => state.theme)

  const handleThemeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const theme = e.target.checked
    dispatch(setTheme({ theme }))
  }

  return (
    <header>
      <HeaderNav>
        <div className="ms-auto">
          <Stack direction="horizontal" gap={5}>
            <Form>
              <Form.Check
                type="switch"
                id=""
                checked={dark}
                label={dark ? <FaRegLightbulb color="#f38e27" /> : <FaMoon size={20} />}
                onChange={e => {
                  handleThemeSwitch(e)
                }}
              />
            </Form>
          </Stack>
        </div>
      </HeaderNav>
    </header>
  )
}

export default HeaderComponent

// inline-flex overflow-hidden relative justify-center items-center  w-7   bg-gray-100 rounded-full dark:bg-gray-600
