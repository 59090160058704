import React from "react"
import { MainBody } from "./components/LayoutStyledComponents"

type Props = {
  children: JSX.Element
}
const MainComponent: React.FC<Props> = ({ children }) => {
  return <MainBody>{children}</MainBody>
}

export default MainComponent
