import { RotatingLines } from "react-loader-spinner"
import Modal from "react-bootstrap/Modal"
import { CenterContainer } from "../styled"

const LoaderComponent = () => {
  return (
    <section>
      <Modal show={true} fullscreen={true} style={{ opacity: "0.5", background: "#fff3e0" }}>
        <Modal.Body>
          <CenterContainer width="" height="" position="fixed">
            <RotatingLines strokeColor="#f38e27" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
          </CenterContainer>
        </Modal.Body>
      </Modal>
    </section>
  )
}
export default LoaderComponent
